import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr } from "@material-ui/core"
import Theme from "../theme"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { graphql } from "gatsby"
import {
  Header,
  Footer,
  CartProvider,
  StoreLocator,
  SEO,
  MobileOrientation,
} from "../../../components"

import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"

import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

export default ({ data, pageContext, location }) => {
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)
  const stores = data.allContentfulStore.edges.filter(function(el) {
    return el.next != null
  })
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)

  const pageMetadata = {
    title: "store locator",
    description: "the store locator",
    url: location.href,
  }

  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <MobileOrientation />
            <SEO siteMetadata={data.site.siteMetadata} />
            <CartProvider cartId={undefined}>
              <Header
                data={data.header.edges[0].node}
                slug={pageContext.slug}
              />
              <StoreLocator
                styling={data.styling.edges[0].node}
                stores={stores}
              />
              <Footer
                data={data.footer.edges[0].node}
                langKey={langKey}
                bgImgLeft={LeafLeft}
                bgImgRight={LeafRight}
              />
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}

export const pageQuery = graphql`
  query($locale: String!) {
    allContentfulStore(filter: { node_locale: { eq: $locale } }) {
      edges {
        next {
          address
          name
          phoneNumber
          storeLocation {
            province
            country
          }
        }
      }
    }
    styling: allContentfulStoreLocatorStyling(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          heroBannerColor
          cityStyling {
            fontColor
            fontSize
            fontWeight
            lineHeight
            textTransform
            fontFamily
            letterSpacing
          }
          addressStyling {
            fontColor
            fontSize
            fontWeight
            lineHeight
            textTransform
            letterSpacing
            fontFamily
          }
          locationStyling {
            fontColor
            fontFamily
            fontSize
            fontWeight
            letterSpacing
            lineHeight
            textTransform
          }
          pageTitle {
            fontColor
            fontFamily
            fontSize
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
        }
      }
    }
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
